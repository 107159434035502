.drag-and-drop {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 2px;
  text-align: center;
  transition: border-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.drag-over {
  border-color: #007bff;
  background-color: #fAfAfA;
}

.drop-area {
  border-radius: 5px;
  /* min-width: 200px;
  min-height: 200px;
  max-width: 400px;
  min-width: 600px; */
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 5px 0;
  font-size: 14px;
}

.text-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 200px;
  width: 85%;
}

.text-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.modern-border {
  border: 1px solid #d4d2d2;
}


